import { ProductCategoryEnum } from '_api-client';
import {
  createListCollection,
  Flex,
  HStack,
  Icon,
  IconButton,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { bulkApproveProductsV2, exportProducts, PRODUCT_SERVICE_QUERY_KEYS } from 'apis/product-apis';
import AppHeader from 'app/app-header';
import { AIIcon, ExcelIcon, InfoFilled } from 'components/icons';
import { KDataTable } from 'components/table/data-table';
import { TableEmptyState } from 'components/table-empty-state/table-empty-state';
import { ActionBarContent, ActionBarRoot, ActionBarSelectionTrigger } from 'components/ui/action-bar';
import { Button } from 'components/ui/button';
import { TableRowSkeleton } from 'components/ui/table-row-skeleton';
import { Tooltip } from 'components/ui/tooltip';
import { useHandleNotification } from 'hooks/useApiNotification';
import useAsyncActionLoader from 'hooks/useAsyncActionLoader';
import { useOrg } from 'hooks/useOrg';
import { useSortableTable } from 'hooks/useSortableTable';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import ProductSearch from 'pages/Products/components/ProductSearch';
import { useEffect, useMemo, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { components, ProductStatusEnum, ProductSubCategoryEnum } from 'schema/types-schema.d';
import {
  BulkApproveSource,
  ProductCategorizeAndApprovedValidationType,
  ProductCategoryReadWithTitle,
  ProductSubCategoryReadWithTitle,
} from 'types/products';
import { ProductInstance } from 'types/shared-types';
import { pluralize } from 'utils';
import { isValidCategory, isValidSubcategory } from 'utils/product-utils';

import { CategorizeModal } from './categorized-modal';
import { CreateProductForm } from './CreateProductModal';
import { FilterButton } from './filter/filter-button';
import CategoryOverview from './product-overview/category-overview';
import ProductTableRow from './product-table-row';

type ProductTableProps = {
  data: ProductInstance[];
  categories: ProductCategoryReadWithTitle[];
  subcategoriesByCategory: Record<ProductCategoryEnum, ProductCategoryReadWithTitle>;
  handleOnOpen: any;
  isAIEnabled: boolean;
  isFilterApplied: boolean;
  isSearchApplied: boolean;
  numberOfUnapprovedProducts: number;
  numberOfPartialApprovedProducts: number;
  tableFilters: UseTableFiltersType;
  isClassifyPending: boolean;
  isPending: boolean;
};

const TABLE_HEAD = ['NAME', 'DESCRIPTION', 'CATEGORY', 'SUBCATEGORY', 'SOURCE', 'TAX EXEMPT', 'STATUS'];
const DEFAULT_VISIBLE_COLUMNS = ['NAME', 'CATEGORY', 'SUBCATEGORY', 'SOURCE', 'STATUS'];
const NUMERIC_COLUMNS = ['AMOUNT', 'TAX AMOUNT'];
const ALL_SORTABLE_FIELDS = ['name', 'status'];
const SORT_HEADERS = ['NAME', 'STATUS'];
const FIELD_MAPPING = {
  NAME: 'name',
  STATUS: 'status',
};

const COLUMN_WIDTHS = {
  NAME: '270px',
  DESCRIPTION: '253px',
  CATEGORY: '210px',
  SUBCATEGORY: '250px',
  SOURCE: '130px',
  TAX_EXEMPT: '100px',
  STATUS: '140px',
};

type BulkApproveParams = {
  payload: { [key: string]: components['schemas']['ProductUpdate'] };
  source?: BulkApproveSource;
};

export const ProductTable = ({
  data,
  categories,
  subcategoriesByCategory,
  handleOnOpen,
  isAIEnabled,
  isFilterApplied,
  isSearchApplied,
  numberOfUnapprovedProducts,
  numberOfPartialApprovedProducts,
  tableFilters,
  isClassifyPending,
  isPending,
}: ProductTableProps) => {
  const { page, order_by, setFilters } = tableFilters;
  const { orgId } = useOrg();

  const queryClient = useQueryClient();
  const { handleFailNotification, handleSuccessNotification } = useHandleNotification();
  const { loading, executeAction } = useAsyncActionLoader(exportProducts);
  const [newValues, setNewValues] = useState<{ [key: string]: components['schemas']['ProductUpdate'] }>({});
  const [approvableProducts, setApprovableProducts] = useState<{ [key: string]: boolean }>({});
  const [selectedRowData, setSelectedRowData] = useState<ProductInstance | undefined>(undefined);
  const [visibleColumns, setVisibleColumns] = useState<string[]>(DEFAULT_VISIBLE_COLUMNS);
  const [checkedRows, setCheckedRows] = useState<string[]>([]);

  const handleToggleColumn = (columns: string[]) => {
    setVisibleColumns(columns);
  };

  const { handleSort, getSortIcon } = useSortableTable({
    initialOrderBy: order_by,
    allSortableFields: ALL_SORTABLE_FIELDS,
    fieldMapping: FIELD_MAPPING,
    setFilters,
  });

  const {
    open: isCategorizeModalOpen,
    onOpen: onOpenCategorizeModal,
    onClose: onCloseCategorizeModal,
  } = useDisclosure();
  const { open: isOverviewDrawerOpen, onOpen: onOpenOverviewDrawer, onClose: onCloseOverviewDrawer } = useDisclosure();

  const { isPending: isApprovePending, mutate: doBulkApprove } = useMutation({
    mutationFn: async ({ payload }: BulkApproveParams) => {
      const bulkApprovePayload = Object.keys(payload).map(key => ({
        id: key,
        product_category: payload[key].product_category,
        product_subcategory: payload[key].product_subcategory,
      }));
      await bulkApproveProductsV2(orgId, bulkApprovePayload);
    },
    onSuccess: (_, variables: BulkApproveParams) => {
      window.dispatchEvent(new Event('enableAi'));
      queryClient.invalidateQueries({ queryKey: [PRODUCT_SERVICE_QUERY_KEYS.list(), orgId] });
      handleSuccessNotification(
        variables.source === 'categorizeAndApprove'
          ? 'Products successfully categorized and approved.'
          : 'Successfully approved the classified products on this page.'
      );
      setCheckedRows([]);
      onCloseCategorizeModal();
    },
  });

  const handleFormikChange = (id: string, values: components['schemas']['ProductUpdate']) => {
    setNewValues(prevState => ({
      ...prevState,
      [id]: values,
    }));
  };

  useEffect(() => {
    setCheckedRows([]);
  }, [page]);

  useEffect(() => {
    const initialApprovableProducts = data?.reduce(
      (acc, product: ProductInstance) => {
        const canApprove =
          product.status !== ProductStatusEnum.APPROVED &&
          product.product_category !== ProductCategoryEnum.UNKNOWN &&
          product.product_subcategory !== ProductSubCategoryEnum.UNKNOWN;
        acc[product.id] = canApprove;
        return acc;
      },
      {} as { [key: string]: boolean }
    );

    setApprovableProducts(initialApprovableProducts);
  }, [data]);

  const handleBulkUpdate = () => {
    const payload: { [key: string]: components['schemas']['ProductUpdate'] } = {};
    const approvableProducts = data?.filter((obj: ProductInstance) => {
      return (
        obj.status !== ProductStatusEnum.APPROVED &&
        isValidCategory(obj.product_category) &&
        isValidSubcategory(obj.product_subcategory)
      );
    });

    for (const obj of approvableProducts) {
      payload[obj.id] = {
        name: obj.name,
        description: obj.description,
        status: ProductStatusEnum.APPROVED,
        product_category: obj.product_category,
        product_subcategory: obj.product_subcategory,
        tax_exempt: obj.tax_exempt,
      };
    }
    for (const key in newValues) {
      if (Object.prototype.hasOwnProperty.call(newValues, key)) {
        const value = newValues[key];
        if (isValidCategory(value.product_category) && isValidSubcategory(value.product_subcategory)) {
          payload[key] = value;
        } else {
          delete payload[key];
        }
      }
    }
    doBulkApprove({ payload, source: 'bulkApprove' });
  };

  const handleCategorizeAndApproveProducts = (values: ProductCategorizeAndApprovedValidationType) => {
    const payload = data
      ?.filter(obj => obj.status !== ProductStatusEnum.APPROVED && checkedRows.includes(obj.id))
      .reduce(
        (acc, obj) => ({
          ...acc,
          [obj.id]: {
            name: obj.name,
            description: obj.description,
            status: ProductStatusEnum.APPROVED,
            product_category: values.new_category,
            product_subcategory: values.new_subcategory,
            tax_exempt: values.new_subcategory === ProductSubCategoryEnum.MISCELLANEOUS_EXEMPT,
          },
        }),
        {}
      );

    if (Object.keys(payload).length) {
      doBulkApprove({ payload, source: 'categorizeAndApprove' });
    }
  };

  const handleExportProducts = async () => {
    try {
      await executeAction(orgId);
      handleSuccessNotification('Product export started. Check your email for download link.');
    } catch (error) {
      handleFailNotification(error);
    }
  };

  const { open, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const initialApprovableProducts = data?.reduce(
      (acc, product: ProductInstance) => {
        const canApprove =
          product.status !== ProductStatusEnum.APPROVED &&
          product.product_category !== ProductCategoryEnum.UNKNOWN &&
          product.product_subcategory !== ProductSubCategoryEnum.UNKNOWN;
        acc[product.id] = canApprove;
        return acc;
      },
      {} as { [key: string]: boolean }
    );

    setApprovableProducts(initialApprovableProducts);
  }, [data]);

  const handleApproveStatusChange = (id: string, canApprove: boolean) => {
    setApprovableProducts(prevState => ({
      ...prevState,
      [id]: canApprove,
    }));
  };

  const showAIIcon = isAIEnabled && (numberOfUnapprovedProducts > 0 || numberOfPartialApprovedProducts > 0);
  const showBanner = numberOfUnapprovedProducts > 10;
  const isDataEmpty = !data || data?.length === 0;
  const countBulkApprove = Object.values(approvableProducts).filter(canApprove => canApprove).length;
  const isCheckboxEnabled = numberOfUnapprovedProducts > 0 || numberOfPartialApprovedProducts > 0;

  const getSubcategoryItemsByCategoryId = (categoryId: ProductCategoryEnum) => {
    const subcategoryItems =
      subcategoriesByCategory && subcategoriesByCategory[categoryId]
        ? subcategoriesByCategory[categoryId].subcategories
        : [];
    if (categoryId === ProductCategoryEnum.UNKNOWN) {
      subcategoryItems.push({
        name: ProductSubCategoryEnum.UNKNOWN,
        description: '',
        example: '',
      });
    }
    return subcategoryItems as unknown as ProductSubCategoryReadWithTitle[];
  };

  const categoriesCollection = useMemo(() => {
    return createListCollection({
      items: categories?.concat([
        { name: ProductCategoryEnum.UNKNOWN, title: 'Unknown', description: '', subcategories: [] },
      ]),
      itemToString: ({ title }) => title,
      itemToValue: ({ name }) => name,
    });
  }, [categories]);

  const getSubCategoriesCollection = useMemo(() => {
    return (product_category: ProductCategoryEnum) =>
      createListCollection({
        items: getSubcategoryItemsByCategoryId(product_category),
        itemToString: ({ title }) => title,
        itemToValue: ({ name }) => name,
      });
  }, [getSubcategoryItemsByCategoryId]);

  return (
    <>
      <HStack
        flexWrap={{ base: 'nowrap', lg: 'nowrap', md: 'nowrap', sm: 'wrap' }}
        width={{ base: 'full', lg: 'full', md: 'full', sm: '100%' }}
        justifyContent={{ base: 'flex-start', lg: 'flex-start', md: 'flex-start', sm: 'space-between' }}
      >
        <AppHeader />
        <Spacer display={{ base: 'block', md: 'block', lg: 'block', sm: 'none' }} />
        <HStack flexWrap={'wrap'} justifyContent={'space-between'}>
          <ProductSearch tableFilters={tableFilters} />
          <Tooltip positioning={{ placement: 'bottom-end' }} content={'Export Products'}>
            <IconButton aria-label={'Export Products'} variant={'transparent-with-icon'} onClick={handleExportProducts}>
              {loading ? (
                <Spinner />
              ) : (
                <Icon w="6" h="6">
                  <ExcelIcon />
                </Icon>
              )}
            </IconButton>
          </Tooltip>
        </HStack>
        <HStack
          flexWrap={'wrap'}
          width={{ base: 'fit-content', lg: 'fit-content', md: 'fit-content', sm: '100%' }}
          justifyContent={{ sm: 'flex-end' }}
          gap={2}
        >
          <FilterButton tableFilters={tableFilters} />

          {showAIIcon && (
            <Tooltip content="Bulk Classify Using Kintsugi Intelligence">
              <IconButton
                variant="transparent-with-icon"
                aria-label="Bulk Classify Using Kintsugi Intelligence"
                cursor={'pointer'}
                onClick={handleOnOpen}
              >
                {isClassifyPending ? <Spinner /> : <AIIcon size="lg" />}
              </IconButton>
            </Tooltip>
          )}
          <Button variant="outline" color="secondary" width={'100px'} onClick={onOpenOverviewDrawer}>
            Overview
          </Button>

          <Button variant={'solid'} colorPalette="blue" width={'100px'} onClick={onOpen}>
            New
          </Button>
        </HStack>
      </HStack>

      {!isAIEnabled && !isDataEmpty && showBanner && (
        <Flex align={'center'} gap={2} mt={{ base: -4, lg: -4, md: 0, sm: 2 }}>
          <HStack
            bgGradient={'linear-gradient(90deg, #04C3CC -0.81%, #527BE4 40.47%, #A373EB 100%)'}
            px={'16px'}
            py={'12px'}
            gap={3}
            borderRadius={'2px'}
          >
            <InfoFilled color={'#FFFFFF'} />
            <Text color={'#FFFFFF'} fontSize={'14px'} lineHeight={'20px'}>
              Too many unapproved products? Bulk classify automatically using Kintsugi Intelligence.
            </Text>
          </HStack>
          <Tooltip content="Bulk Classify Using Kintsugi Intelligence">
            <Icon w="8" h="8">
              <AIIcon onClick={handleOnOpen} cursor={'pointer'} />
            </Icon>
          </Tooltip>
        </Flex>
      )}
      {!isDataEmpty && countBulkApprove > 0 && (
        <HStack height={'32px'} gap={4} mt={{ base: 0, lg: 0, md: 0, sm: 4 }}>
          <Text fontSize={'16px'} fontWeight={'500'} color={'#383D58'}>
            Approve {countBulkApprove} categorized {countBulkApprove > 1 ? 'products' : 'product'} on this page?
          </Text>
          <Button colorPalette="blue" loading={isApprovePending} onClick={handleBulkUpdate}>
            Approve
          </Button>
        </HStack>
      )}
      {isDataEmpty && !isPending ? (
        <TableEmptyState
          tableName="Products"
          uniqueHelperTxt={
            isFilterApplied || isSearchApplied
              ? ''
              : 'Connect your integrations or import some transactions to get started.'
          }
          isFilterApplied={isFilterApplied}
          isSearchApplied={isSearchApplied}
        />
      ) : (
        <KDataTable
          headers={TABLE_HEAD}
          numericCols={NUMERIC_COLUMNS}
          defaultVisibleColumns={visibleColumns}
          onVisibleColumnsChange={handleToggleColumn}
          showColumnFilter
          sortHeaders={SORT_HEADERS}
          getSortIcon={getSortIcon}
          handleSort={handleSort}
          columnWidths={COLUMN_WIDTHS}
          isPending={isPending || isApprovePending}
          enableCheckboxSelection={isCheckboxEnabled}
          checkedRows={checkedRows}
          onRowCheck={setCheckedRows}
          getRowId={row => row?.id}
          isRowCheckable={data => data?.status !== ProductStatusEnum.APPROVED}
        >
          {data?.map((row: ProductInstance) => {
            return (
              <ProductTableRow
                data={row}
                key={row.id}
                categoriesData={{
                  categories: categoriesCollection,
                  getSubCategories: getSubCategoriesCollection,
                }}
                visibleColumns={visibleColumns}
                onSelectChange={(id: string, values: components['schemas']['ProductUpdate']) =>
                  handleFormikChange(id, values)
                }
                onSelectRowData={setSelectedRowData}
                onOpenEditModal={onOpen}
                onApproveStatusChange={handleApproveStatusChange}
              />
            );
          })}
          {isPending && <TableRowSkeleton columns={DEFAULT_VISIBLE_COLUMNS} showEmptyColumn={true} />}
        </KDataTable>
      )}
      {open && (
        <CreateProductForm
          isOpen={open}
          onClose={() => {
            onClose();
            setSelectedRowData(undefined);
          }}
          categories={categories}
          subcategoriesByCategory={subcategoriesByCategory}
          data={selectedRowData}
        />
      )}

      {isCategorizeModalOpen && (
        <CategorizeModal
          setCheckedRows={setCheckedRows}
          isOpen={isCategorizeModalOpen}
          onClose={onCloseCategorizeModal}
          categories={categories}
          subcategoriesByCategory={subcategoriesByCategory}
          selectedCount={checkedRows.length}
          onSubmit={handleCategorizeAndApproveProducts}
        />
      )}

      {isOverviewDrawerOpen && (
        <CategoryOverview
          isOpen={isOverviewDrawerOpen}
          onClose={onCloseOverviewDrawer}
          categories={categories}
          subcategoriesByCategory={subcategoriesByCategory}
        />
      )}

      <ActionBarRoot open={!isCategorizeModalOpen && checkedRows.length > 0}>
        <ActionBarContent
          bgColor="gray.900"
          color="white"
          minW="660px"
          height="48px"
          justifyContent="space-between"
          borderRadius="l1"
          p={4}
        >
          <Text fontSize="sm" fontWeight="500">
            {checkedRows.length} {pluralize('product', checkedRows.length)} selected
          </Text>
          <HStack>
            <Button size="xs" fontSize="14px" fontWeight="500" onClick={onOpenCategorizeModal} colorPalette="blue">
              Categorize & Approve
            </Button>
            <ActionBarSelectionTrigger asChild onClick={() => setCheckedRows([])}>
              <IconButton variant="transparent-with-icon" size="xs">
                <MdClose />
              </IconButton>
            </ActionBarSelectionTrigger>
          </HStack>
        </ActionBarContent>
      </ActionBarRoot>
    </>
  );
};
