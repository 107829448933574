import { Box, createListCollection, Flex, HStack, Input, Stack, Text, Textarea, VStack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createProduct, PRODUCT_SERVICE_QUERY_KEYS, updateProducts } from 'apis/product-apis';
import { Button } from 'components/ui/button';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
  DialogTitle,
} from 'components/ui/dialog';
import { Field } from 'components/ui/field';
import { NativeSelectField, NativeSelectRoot } from 'components/ui/native-select';
import {
  SelectContent,
  SelectItem,
  SelectRoot,
  SelectTrigger,
  SelectValueText,
  SubCategorySelectItem,
} from 'components/ui/select';
import { SOURCE_CONSTANTS } from 'constants/source-constants';
import { useFormik } from 'formik';
import { useHandleNotification } from 'hooks/useApiNotification';
import { useOrg } from 'hooks/useOrg';
import { useMemo, useRef } from 'react';
import {
  ProductCategoryEnum,
  ProductCategoryReadWithTitle,
  ProductCreate,
  ProductCreateSchema,
  ProductSubCategoryReadWithTitle,
  ProductUpdate,
  ProductUpdateSchema,
} from 'types/products';
import { ProductInstance, ProductStatusEnum } from 'types/shared-types';
import { getHumanReadableString } from 'utils/enum-helpers';
import { getProductCategoryTitle, getProductSubcategoryTitle } from 'utils/product-utils';

type CreateProductFormProps = {
  isOpen: boolean;
  onClose: () => void;
  categories: ProductCategoryReadWithTitle[];
  subcategoriesByCategory: Record<ProductCategoryEnum, ProductCategoryReadWithTitle>;
  data?: ProductInstance | undefined;
};

const FORM_INITIAL_VALUES = {
  external_id: '',
  name: '',
  description: '',
  source: 'OTHER',
  status: ProductStatusEnum.APPROVED,
  product_category: ProductCategoryEnum.UNKNOWN,
  product_subcategory: '',
  tax_exempt: false,
};

export const CreateProductForm = ({
  isOpen,
  onClose,
  subcategoriesByCategory,
  categories,
  data: savedData,
}: CreateProductFormProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const { handleSuccessNotification } = useHandleNotification();

  const { mutate: createProductMutation, isPending: isCreateProductPending } = useMutation({
    mutationFn: (payload: ProductCreate) => {
      return createProduct(orgId, payload);
    },
    onSuccess: () => {
      onCloseModal();
      handleSuccessNotification('Product successfully created.');
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_SERVICE_QUERY_KEYS.list(), orgId],
      });
    },
  });

  const { mutate: updateProductMutation, isPending: isUpdateProductPending } = useMutation({
    mutationFn: (payload: ProductUpdate) => {
      return updateProducts(payload.id, orgId, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PRODUCT_SERVICE_QUERY_KEYS.list(), orgId] });
      handleSuccessNotification('Product updated successfully.');
      onCloseModal();
    },
  });

  const { values, handleChange, setFieldValue, handleSubmit, resetForm, errors, touched } = useFormik({
    initialValues: savedData || FORM_INITIAL_VALUES,
    validationSchema: savedData ? ProductUpdateSchema : ProductCreateSchema,
    enableReinitialize: true,
    onSubmit: values => {
      if (savedData && 'id' in values && values.id) {
        updateProductMutation(values);
      } else {
        createProductMutation(values);
      }
    },
  });

  const onCloseModal = () => {
    resetForm();
    onClose();
  };
  const dropdownItems = SOURCE_CONSTANTS?.map((item: any) => ({ label: getHumanReadableString(item), value: item }));

  const getSubcategoryItemsByCategoryId = (categoryId: ProductCategoryEnum) => {
    const subcategoryItems = (
      subcategoriesByCategory && subcategoriesByCategory[categoryId]
        ? subcategoriesByCategory[categoryId].subcategories
        : []
    ) as ProductSubCategoryReadWithTitle[];
    return subcategoryItems;
  };

  const categoriesCollection = useMemo(() => {
    return createListCollection({
      items: categories,
      itemToString: ({ title }) => title,
      itemToValue: ({ name }) => name,
    });
  }, [categories]);

  const subCategoriesCollection = useMemo(() => {
    return createListCollection({
      items: getSubcategoryItemsByCategoryId(values.product_category),
      itemToString: ({ title }) => title,
      itemToValue: ({ name }) => name,
    });
  }, [getSubcategoryItemsByCategoryId, values.product_category]);

  return (
    <DialogRoot
      size={'md'}
      open={isOpen}
      onOpenChange={({ open }) => {
        if (!open) {
          onCloseModal();
        }
      }}
    >
      <DialogBackdrop />
      <DialogContent ref={contentRef}>
        <DialogHeader>
          <DialogTitle>{savedData ? 'Edit Product' : 'New Product'}</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <form noValidate>
            <Stack gap={'16px'}>
              <HStack>
                <Field
                  label="ID"
                  invalid={!!(errors.external_id && touched.external_id)}
                  errorText={errors.external_id}
                  required
                >
                  <Input
                    id="external_id"
                    type="external_id"
                    name="external_id"
                    value={values.external_id}
                    onChange={handleChange}
                  />
                </Field>
                <Field label="Name" invalid={!!(errors.name && touched.name)} errorText={errors.name} required>
                  <Input id="name" type="name" name="name" value={values.name} onChange={handleChange} />
                </Field>
              </HStack>

              <Field
                label="Description"
                invalid={!!(errors.description && touched.description)}
                errorText={errors.description}
              >
                <Textarea
                  id="description"
                  name="description"
                  value={values.description ?? ''}
                  onChange={handleChange}
                  borderRadius={'1px'}
                  size={'sm'}
                />
              </Field>
              <HStack align={'baseline'}>
                <Field
                  label="Category"
                  invalid={!!(errors.product_category && touched.product_category)}
                  errorText={errors.product_category}
                  required
                >
                  <SelectRoot
                    collection={categoriesCollection}
                    value={values.product_category ? [values.product_category] : undefined}
                    onValueChange={({ value }) => {
                      setFieldValue('product_category', value[0]);
                      setFieldValue('product_subcategory', '');
                    }}
                  >
                    <SelectTrigger>
                      <SelectValueText placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent portalRef={contentRef}>
                      {categoriesCollection.items.map(item => (
                        <SelectItem key={item.name} item={item}>
                          {getProductCategoryTitle(item.name)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
                <Field
                  label="Subcategory"
                  invalid={!!(errors.product_subcategory && touched.product_subcategory)}
                  errorText={errors.product_subcategory}
                  required
                  disabled={!subCategoriesCollection.items.length}
                >
                  <SelectRoot
                    colorPalette="blue"
                    collection={subCategoriesCollection}
                    value={values.product_subcategory ? [values.product_subcategory] : undefined}
                    onValueChange={({ value }) => setFieldValue('product_subcategory', value[0])}
                  >
                    <SelectTrigger>
                      <SelectValueText placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent portalRef={contentRef}>
                      {subCategoriesCollection.items.map(item => (
                        <SubCategorySelectItem
                          tooltip={
                            <VStack p={'8px'} gap={4}>
                              <Box w="full">
                                <Text fontSize={'xs'}>{!!item.description && 'Description:'}</Text>
                                <Text fontSize={'xs'}>{item.description}</Text>
                              </Box>
                              <Box w="full">
                                <Box minW={'60px'} mr={'30px'}>
                                  <Text fontSize={'xs'}>{!!item.example && 'Examples:'}</Text>
                                  <Text fontSize={'xs'}>{item.example}</Text>
                                </Box>
                              </Box>
                            </VStack>
                          }
                          key={item.name}
                          item={item}
                        >
                          {getProductSubcategoryTitle(item.name)}
                        </SubCategorySelectItem>
                      ))}
                    </SelectContent>
                  </SelectRoot>
                </Field>
              </HStack>
              <HStack alignItems="center" gap={4}>
                <Field label="Source" required>
                  <NativeSelectRoot>
                    <NativeSelectField
                      placeholder="Select Source"
                      id="source"
                      name="source"
                      value={values.source}
                      onChange={handleChange}
                    >
                      {dropdownItems.map(({ label, value }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </NativeSelectField>
                  </NativeSelectRoot>
                </Field>
              </HStack>
            </Stack>
          </form>
        </DialogBody>
        <DialogFooter>
          <Flex gap={2}>
            <Button variant={'outline'} color={'secondary'} onClick={onCloseModal}>
              Cancel
            </Button>
            <Button
              variant={'solid'}
              colorPalette="blue"
              width={'90px'}
              onClick={() => handleSubmit()}
              loading={isCreateProductPending || isUpdateProductPending}
            >
              Save
            </Button>
          </Flex>
        </DialogFooter>
        <DialogCloseTrigger />
      </DialogContent>
    </DialogRoot>
  );
};
