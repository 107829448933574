import { Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getProductCategories,
  getProductClassificationConfigs,
  getProducts,
  PRODUCT_CATEGORIES_SUBCATEGORIES_LIST_STATE_KEY,
  PRODUCT_CLASSIFICATION_STATE_KEY,
  PRODUCT_SERVICE_QUERY_KEYS,
} from 'apis/product-apis';
import { PaginationButtons } from 'components/pagination/pagination-buttons';
import { toaster } from 'components/ui/toaster';
import { useOrg } from 'hooks/useOrg';
import { DEFAULT_PAGE_SIZE, useTableFilters } from 'hooks/useTableFilters';
import keyBy from 'lodash/keyBy';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProductCategoryEnum, ProductCategoryReadWithTitle } from 'types/products';
import { ApiResponse, ProductInstance, ProductStatusEnum } from 'types/shared-types';
import { getProductCategoryTitle, getProductSubcategoryTitle } from 'utils/product-utils';

import ClassifyConfirmationModal from './components/product-classifier/classify-confirmation-modal';
import { ProductClassifierConfigModal } from './components/product-classifier/product-classifier-config-modal';
import { ProductTable } from './components/table';
import { useBulkClassifyProducts } from './hooks/paged-product-classifier';

const Products = () => {
  const tableFilters = useTableFilters();
  const { page, size, status__in, product_category__in, source__in, order_by, query, setFilters } = tableFilters;
  const filterObj = { status__in, product_category__in, source__in };
  const { orgId } = useOrg();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const { open, onClose, onOpen } = useDisclosure();
  const {
    open: isOpenClassifyConfirmModal,
    onClose: onCloseClassifyConfirmModal,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onOpen: onOpenClassifyConfirmModal,
  } = useDisclosure();

  const { mutateAsync: doPagedBulkClassify } = useBulkClassifyProducts();

  const { isPending, isRefetching, data } = useQuery<
    ApiResponse<ProductInstance>,
    null,
    ApiResponse<ProductInstance> & { unapprovedProductCounts: number; partialApprovedProductCounts: number }
  >({
    queryKey: [
      PRODUCT_SERVICE_QUERY_KEYS.list(),
      orgId,
      page,
      size,
      status__in,
      product_category__in,
      source__in,
      order_by,
      query,
    ],
    queryFn: async () => {
      const res = await getProducts({
        orgId,
        params: {
          page,
          size,
          status__in,
          product_category__in,
          source__in,
          order_by,
          query,
        },
      });
      return res.data;
    },
    select: data => {
      return {
        ...data,
        unapprovedProductCounts: data.items.filter(({ status }) => status === ProductStatusEnum.PENDING).length,
        partialApprovedProductCounts: data.items.filter(({ status }) => status === ProductStatusEnum.PARTIALLY_APPROVED)
          .length,
      };
    },
    staleTime: 0,
    refetchOnMount: true,
  });

  useEffect(() => {
    const totalUnapprovedProducts = data ? data.unapprovedProductCounts + data.partialApprovedProductCounts : 0;

    const newParams = new URLSearchParams(searchParams);
    newParams.set('needClassifyProdSupport', String(totalUnapprovedProducts > 500));
    setSearchParams(newParams);
  }, [data, setSearchParams]);

  const numberOfUnapprovedProducts = data?.unapprovedProductCounts ?? 0;
  const numberOfPartialApprovedProducts = data?.partialApprovedProductCounts ?? 0;
  const productData = data?.items || [];
  const totalPages = data?.pages ?? 0;
  const isPaginationEnable = !!(productData.length > 0 && (data?.total ?? 0) > DEFAULT_PAGE_SIZE);

  const { data: productCategories } = useQuery({
    queryKey: [PRODUCT_CATEGORIES_SUBCATEGORIES_LIST_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getProductCategories(orgId);
      return data;
    },
    select: data => {
      const transformedData = data.map(({ name, subcategories, ...rest }) => ({
        ...rest,
        name,
        title: getProductCategoryTitle(name),
        subcategories: subcategories.map(({ name: subCatName, ...rest }) => ({
          ...rest,
          name: subCatName,
          title: getProductSubcategoryTitle(subCatName),
        })),
      }));

      return {
        categories: transformedData || [],
        subcategories:
          (keyBy(transformedData, 'name') as unknown as Record<ProductCategoryEnum, ProductCategoryReadWithTitle>) ||
          {},
      };
    },
    refetchOnWindowFocus: false,
  });

  const { data: productConfig } = useQuery({
    queryKey: [PRODUCT_CLASSIFICATION_STATE_KEY, orgId, 'product', 'config'],
    queryFn: async () => {
      const { data } = await getProductClassificationConfigs(orgId);
      return data;
    },
    refetchOnWindowFocus: false,
  });

  const isAIEnabled = productConfig?.some(item => item.ai_enabled);

  const { isPending: isClassifyPending, mutateAsync: doBulkClassify } = useMutation({
    mutationFn: async () => {
      await doPagedBulkClassify();
    },
    onSuccess: () => {
      localStorage.setItem(`${orgId}_ai_enabled`, 'true');
      toaster.create({
        type: 'info',
        duration: 10000,
        title: (
          <Text color="#262B47" fontSize={'16px'} fontWeight={'semibold'} mb={'1px'}>
            Product Classification in Progress
          </Text>
        ),
        description:
          'Kintsugi Intelligence is classifying your products. It may take some time; feel free to come back later to check the update.',
      });
      open && handleOnClose();
      isOpenClassifyConfirmModal && onCloseClassifyConfirmModal();
      queryClient.invalidateQueries({
        queryKey: [PRODUCT_SERVICE_QUERY_KEYS.list(), status__in, product_category__in, filterObj, query],
      });
    },
  });

  const handleOnOpen = async () => {
    if (!productConfig?.length) {
      onOpen();
      return;
    }

    if (numberOfPartialApprovedProducts > 0) {
      onOpenClassifyConfirmModal();
      return;
    }
    await doBulkClassify();
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <Stack height={'100%'} gap={'1rem'}>
      <ProductTable
        data={productData}
        categories={productCategories?.categories || []}
        subcategoriesByCategory={
          productCategories?.subcategories || ({} as Record<ProductCategoryEnum, ProductCategoryReadWithTitle>)
        }
        handleOnOpen={handleOnOpen}
        isAIEnabled={isAIEnabled ?? false}
        isFilterApplied={!!(status__in || product_category__in || source__in)}
        isSearchApplied={!!query}
        numberOfUnapprovedProducts={numberOfUnapprovedProducts}
        numberOfPartialApprovedProducts={numberOfPartialApprovedProducts}
        tableFilters={tableFilters}
        isClassifyPending={isClassifyPending}
        isPending={isPending || isRefetching}
      />
      {isPaginationEnable && (
        <PaginationButtons size={size} currentPage={page} totalPages={totalPages} setFilters={setFilters} />
      )}

      {open && (
        <ProductClassifierConfigModal
          orgId={orgId}
          isOpen={open}
          onClose={handleOnClose}
          categories={productCategories?.categories || []}
          onSubmit={doBulkClassify}
          subcategoriesByCategory={
            productCategories?.subcategories || ({} as Record<ProductCategoryEnum, ProductCategoryReadWithTitle>)
          }
          productConfig={productConfig ?? []}
          isClassifyPending={isClassifyPending}
        />
      )}
      {isOpenClassifyConfirmModal && (
        <ClassifyConfirmationModal
          isOpen={isOpenClassifyConfirmModal}
          onClose={onCloseClassifyConfirmModal}
          onConfirm={doBulkClassify}
          isClassifyPending={isClassifyPending}
        />
      )}
    </Stack>
  );
};

export default Products;
