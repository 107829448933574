import { Box, CheckboxGroup, HStack, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import {
  getProductCategories,
  getProductsSummary,
  PRODUCT_CATEGORIES_SUBCATEGORIES_LIST_STATE_KEY,
  PRODUCT_SUMMARY_STATE_KEY,
} from 'apis/product-apis';
import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from 'components/ui/accordion';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { useOrg } from 'hooks/useOrg';
import { UseTableFiltersType } from 'hooks/useTableFilters';
import { useEffect, useState } from 'react';
import { ProductCategoryEnum, SourceEnum } from 'schema/types-schema';
import { getCategory, getHumanReadableString } from 'utils/enum-helpers';

type FilterContentProps = {
  onClose: () => void;
  tableFilters: UseTableFiltersType;
};

export const FilterContent = ({ onClose, tableFilters }: FilterContentProps) => {
  const [accordionIndex, setAccordionIndex] = useState<string[]>([]);
  const { orgId } = useOrg();

  const { status__in, product_category__in, source__in, setFilters, resetFilters } = tableFilters;
  const [selectedStatus, setSelectedStatus] = useState<string[]>(status__in?.split(',') || []);
  const [selectedCategory, setSelectedCategory] = useState<string[]>(product_category__in?.split(',') || []);
  const [selectedSource, setSelectedSource] = useState<string[]>(source__in?.split(',') || []);

  const { data: categories = [] } = useQuery({
    queryKey: [PRODUCT_CATEGORIES_SUBCATEGORIES_LIST_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getProductCategories(orgId);
      return data || [];
    },
    staleTime: 0,
    gcTime: 5 * 60 * 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    retry: 1,
  });

  const { data: sources = [] } = useQuery({
    queryKey: [PRODUCT_SUMMARY_STATE_KEY, orgId],
    queryFn: async () => {
      const { data } = await getProductsSummary(orgId);
      return data.summary.sources || [];
    },
    staleTime: 0,
    gcTime: 5 * 60 * 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    retry: 1,
  });

  const handleSave = () => {
    setFilters({ status__in: selectedStatus, product_category__in: selectedCategory, source__in: selectedSource });
    onClose();
  };

  const handleClear = () => {
    resetFilters();
    setSelectedStatus([]);
    setSelectedCategory([]);
    setSelectedSource([]);
    onClose();
  };

  useEffect(() => {
    const newIndexes = [];
    if (status__in) newIndexes.push('status__in');
    if (product_category__in) newIndexes.push('product_category__in');
    if (source__in) newIndexes.push('source__in');
    setAccordionIndex(newIndexes);
  }, []);

  const hasFilter = selectedStatus.length > 0 || selectedCategory.length > 0 || selectedSource.length > 0;

  return (
    <Stack overflow={'auto'} color={'gray.900'}>
      <HStack position={'sticky'} top={0} bgColor={'#fff'} zIndex={1} justifyContent={'space-between'}>
        <Text fontSize={'18px'} fontWeight={500}>
          Filters
        </Text>
        {hasFilter && (
          <Text fontSize={'12px'} color={'#4285F4'} cursor={'pointer'} onClick={handleClear}>
            Clear All Filters
          </Text>
        )}
      </HStack>
      <Stack>
        <AccordionRoot
          variant="plain"
          multiple
          value={accordionIndex}
          onValueChange={({ value }) => setAccordionIndex(value)}
        >
          <AccordionItem value="status__in">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Status
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent gap={2} mb={2}>
              <CheckboxGroup value={selectedStatus} onValueChange={values => setSelectedStatus(values)}>
                {[
                  { key: 'APPROVED', label: 'Approved' },
                  { key: 'PENDING', label: 'Unapproved' },
                  { key: 'PARTIALLY_APPROVED', label: 'Partially Approved' },
                ].map(({ key, label }) => (
                  <Checkbox key={key} value={key}>
                    {label}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </AccordionItemContent>
          </AccordionItem>
          <AccordionItem value="product_category__in">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Category
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent gap={2} mb={2}>
              <CheckboxGroup value={selectedCategory} onValueChange={values => setSelectedCategory(values)}>
                {categories?.map(({ name }: { name: ProductCategoryEnum }) => (
                  <Checkbox key={name} value={name}>
                    {getCategory(name)}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </AccordionItemContent>
          </AccordionItem>
          <AccordionItem value="source__in">
            <AccordionItemTrigger>
              <Box as="span" fontSize={'sm'} fontWeight={'medium'} flex="1" textAlign="left">
                Source
              </Box>
            </AccordionItemTrigger>
            <AccordionItemContent gap={2} mb={2}>
              <CheckboxGroup value={selectedSource} onValueChange={values => setSelectedSource(values)}>
                {sources?.map((source: SourceEnum) => (
                  <Checkbox key={source} value={source}>
                    {getHumanReadableString(source)}
                  </Checkbox>
                ))}
              </CheckboxGroup>
            </AccordionItemContent>
          </AccordionItem>
        </AccordionRoot>
        <HStack direction="row" justifyContent={'space-around'} gap="18px" mt={4}>
          <Button variant="outline" color="secondary" onClick={onClose} width={'132px'} height={'32px'}>
            Cancel
          </Button>
          <Button variant="solid" onClick={handleSave} width={'132px'} height={'32px'}>
            Apply
          </Button>
        </HStack>
      </Stack>
    </Stack>
  );
};
